import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { gsap } from "@helpers/gsap";
import imagesLoaded from "imagesloaded";

import { Slider } from "@components/HomeSlider";
import Header from "../components/Header";
import Seo from "@components/Seo";

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

import { useStaticAssets } from "@hooks/queries/index.js";

// page component
const IndexPage = ({ data }) => {
  let { logoNoText } = useStaticAssets();
  const [isImagesLoaded, setIsImagesLoaded] = useState(false);

  useEffect(() => {
    let pageWrapper = document.querySelectorAll(".indexPage");
    let imgLoad = imagesLoaded(pageWrapper);
    imgLoad.on("always", () => {
      console.log("==> images loaded...");
      setIsImagesLoaded(true);
    });

    // init splittingJS
    const Splitting = window.Splitting;
    Splitting();
  }, []);

  return (
    <>
      <Seo />
      <main className="indexPage">
        {isImagesLoaded ? (
          ""
        ) : (
          <div className="loading">
            <img className="logo-small" src={logoNoText.publicURL} alt="" />
            <p>Loading</p>
          </div>
        )}
        <div className="indexPage__header">
          <Header />
        </div>
        <Slider
          data={data.featuredProjects.nodes}
          imagesLoaded={isImagesLoaded}
        />
        <div className="indexPage__socials">
          <a className="link-underline-hover" href="#">
            Facebook.
          </a>
          <a className="link-underline-hover" href="#">
            Instagram.
          </a>
        </div>
      </main>
    </>
  );
};

export const query = graphql`
  query {
    featuredProjects: allStrapiProject(
      filter: { featured: { eq: true } }
      sort: { fields: published_at, order: DESC }
    ) {
      nodes {
        title
        fields {
          slug
        }
        excerpt
        id
        banner_image {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;

import React, { useState, useEffect, useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// import Splitting from 'splitting';
import { gsap } from "@helpers/gsap";

import { useStaticAssets } from "@hooks/queries";
import { Link } from "gatsby";

const sliderElements = {
  slide: {
    all: [],
  },
  controls: {
    next: null,
    previous: null,
  },
  nextSlideTitleIndicator: {
    el: null,
    chars: null,
  },
};

// slides
const Slider = ({ data, imagesLoaded }) => {
  const [sliderCurrentIndex, setSliderCurrentIndex] = useState(0);
  const sliderCurrentIndexRef = useRef(0);
  const autoNextTimeoutID = useRef(undefined);
  let isAnimating = useRef(false);
  const textAnimSettings = {
    duration: 0.5,
    stagger: 0.014,
    y: "-100%",
  };

  // creates a slide object
  class slideObj {
    constructor(el) {
      this.el = el;
      this.DOM = {};
      this.DOM.title = this.el.querySelector(".slide__title");
      this.DOM.excerpt = this.el.querySelector(".slide__excerpt");
      this.DOM.link = this.el.querySelector(".slide__link");
      this.DOM.imageReveal = this.el.querySelector(".slide__image-reveal");
      this.DOM.titleChars = this.el.querySelectorAll(".slide__title .char");
      this.DOM.titleWords = this.el.querySelectorAll(".slide__title .word");
      this.DOM.linkChars = this.el.querySelectorAll(".slide__link .char");
      // this.DOM.nextIndicator = document.querySelector('.')
    }
  }

  const getNextIndex = (currentIndex) => {
    let newIndex = currentIndex < data.length - 1 ? currentIndex + 1 : 0;

    return newIndex;
  };

  const getPreviousIndex = (currentIndex) => {
    let newIndex = currentIndex <= 0 ? data.length - 1 : currentIndex - 1;

    return newIndex;
  };

  //
  function animateSlideIn(index) {
    let currentSlide = sliderElements.slide.all[index];

    return gsap
      .timeline({
        paused: true,
        onComplete: () => {
          isAnimating.current = false;
          autoNextTimeoutID.current = setTimeout(() => {
            goToNextSlide();
          }, 2000);
        },
        onStart: () => {
          isAnimating.current = true;
        },
      })
      .addLabel("start")
      .set(
        [
          ...currentSlide.el.querySelectorAll(".overflow-hidden-anim"),
          ...currentSlide.el.querySelectorAll("h1"),
          sliderElements.nextSlideTitleIndicator.el,
        ],
        {
          opacity: 1,
        }
      )
      .to(currentSlide.DOM.imageReveal, {
        width: "0%",
        duration: 0.8,
      })
      .to(currentSlide.DOM.titleChars, { ...textAnimSettings, y: 0 })
      .to(currentSlide.DOM.linkChars, textAnimSettings)
      .to(currentSlide.DOM.excerpt, {
        x: 0,
        duration: 0.5,
        opacity: 1,
      })
      .to(sliderElements.nextSlideTitleIndicator.chars, textAnimSettings);
  }

  function animateSlideOut(index) {
    let currentSlide = sliderElements.slide.all[index];

    return gsap
      .timeline({
        paused: true,
        onComplete: () => {
          isAnimating.current = false;
        },
        onStart: () => {
          isAnimating.current = true;
        },
      })
      .addLabel("start")
      .to(currentSlide.DOM.titleChars, { ...textAnimSettings, y: "100%" })
      .to(currentSlide.DOM.linkChars, {
        ...textAnimSettings,
        y: 0,
      })
      .to(currentSlide.DOM.excerpt, {
        x: "-30px",
        duration: 0.5,
        opacity: 0,
      })
      .to(currentSlide.DOM.imageReveal, {
        width: "100%",
        duration: 0.8,
      })
      .to(sliderElements.nextSlideTitleIndicator.chars, {
        ...textAnimSettings,
        y: 0,
      });
  }

  function goToNextSlide() {
    // prevent triggering this function on multiple clicks while animation is still running
    if (isAnimating.current) {
      // console.log('is animating!');
      return;
    }
    console.log("animation entered. id ->", autoNextTimeoutID.current);

    // clear auto next timeout
    clearTimeout(autoNextTimeoutID.current);

    // play entrance animation
    animateSlideOut(sliderCurrentIndexRef.current)
      .play()
      .add(() => {
        setSliderCurrentIndex(getNextIndex(sliderCurrentIndexRef.current));
      })
      .add(() =>
        animateSlideIn(getNextIndex(sliderCurrentIndexRef.current)).play()
      );
  }

  function goToPreviousSlide() {
    if (isAnimating.current) return;

    // clear auto next timeout
    clearTimeout(autoNextTimeoutID.current);

    // play entrance animation
    animateSlideOut(sliderCurrentIndexRef.current)
      .play()
      .add(() => {
        setSliderCurrentIndex(getPreviousIndex(sliderCurrentIndexRef.current));
      })
      .add(() =>
        animateSlideIn(getPreviousIndex(sliderCurrentIndexRef.current)).play()
      );
  }

  // TODO:
  //  * go to (n) slide
  //  * make animation interruptable with arrow btns

  // animation init code
  useEffect(() => {
    if (!imagesLoaded) return;

    // 1. get all addition required DOM elements for animation

    // controls
    sliderElements.controls.next = document.querySelector(".arrow.next");
    sliderElements.controls.previous =
      document.querySelector(".arrow.previous");

    // all slides
    let slides = document.querySelectorAll(".slide");

    [...slides].forEach((slide) => {
      sliderElements.slide.all.push(new slideObj(slide));
    });

    sliderElements.slide.all.forEach((slide) => {
      slide.DOM.titleWords.forEach((word) => {
        word.classList.add("overflow-hidden-anim");
      });
    });

    // next slide title indicator
    let nextIndicator = document.querySelector(
      ".indexPage-slider-controls-info__next"
    );
    sliderElements.nextSlideTitleIndicator.el = nextIndicator;
    sliderElements.nextSlideTitleIndicator.chars =
      nextIndicator.querySelectorAll(".char");

    // 2. Attach event listener to next and previous btns
    sliderElements.controls.next.addEventListener("click", goToNextSlide);
    sliderElements.controls.previous.addEventListener(
      "click",
      goToPreviousSlide
    );

    // 3. Play entrance animation for first entrance *
    animateSlideIn(0).play();
  }, [imagesLoaded]);

  // update ref so callback fns can always reference latest slider index values, i.e. avoid closure bugs**
  useEffect(() => {
    sliderCurrentIndexRef.current = sliderCurrentIndex;
  }, [sliderCurrentIndex]);

  useEffect(() => {
    return () => {
      console.log("...clearing interval id -->", autoNextTimeoutID.current);
      clearTimeout(autoNextTimeoutID.current);
    };
  }, []);

  return (
    <>
      <div className="indexPage-slider">
        {data.map((d, i) => (
          <Slide data={d} index={i} key={i} currentIndex={sliderCurrentIndex} />
        ))}
      </div>
      <SliderControls
        data={data}
        sliderIndex={[sliderCurrentIndex, setSliderCurrentIndex]}
        getNextIndex={getNextIndex}
        getPreviousIndex={getPreviousIndex}
      />
    </>
  );
};

const Slide = ({ data, index, currentIndex }) => {
  let bannerImg = getImage(data.banner_image.localFile);

  return (
    <div
      className={`slide ${index === currentIndex ? "slide--current" : ""}`}
      key={data.id}
    >
      <div className="slide__info">
        <h1 className="slide__title" data-splitting="chars">
          {data.title}
        </h1>
        <p className="slide__excerpt " data-splitting="chars">
          {data.excerpt}
        </p>
        <Link
          className="slide__link overflow-hidden-anim link-underline-hover"
          // to={`/projects/${data.fields.slug}`}
          to="#"
          data-splitting="chars" 
        >
          VIEW PROJECT
        </Link>
      </div>
      <div className="slide__image-wrapper">
        <div className="slide__image-reveal"></div>
        <GatsbyImage
          image={bannerImg}
          alt={data.title + " banner image"}
          className="slide__image-gatsbyImage"
          imgClassName="slide__image"
          width="100%"
        />
      </div>
    </div>
  );
};

const SliderControls = ({ data, sliderIndex, getNextIndex }) => {
  const { lineArrow } = useStaticAssets();
  const [sliderCurrentIndex, setSliderCurrentIndex] = sliderIndex;

  return (
    <>
      <div className="indexPage-slider-controls-info">
        <span className="nextText">NEXT</span>
        <em
          className="indexPage-slider-controls-info__next overflow-hidden-anim"
          data-splitting="chars"
        >
          {/* This doesn't animate in correctly after the initial animation. To be fixed. */}
          {data[getNextIndex(sliderCurrentIndex)].title}
        </em>
      </div>
      <div className="indexPage-slider-controls-btns">
        <span className="divider"></span>
        <button className="arrow previous">
          <span className="hover-slide"></span>
          <img src={lineArrow.publicURL} />
        </button>
        <span className="divider"></span>
        <button className="arrow next">
          <span className="hover-slide"></span>
          <img src={lineArrow.publicURL} />
        </button>
      </div>
    </>
  );
};

export { Slider };
